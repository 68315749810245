import { render, staticRenderFns } from "./FooterActionRow.vue?vue&type=template&id=2fda9e55&scoped=true"
import script from "./FooterActionRow.vue?vue&type=script&lang=js"
export * from "./FooterActionRow.vue?vue&type=script&lang=js"
import style0 from "./FooterActionRow.vue?vue&type=style&index=0&id=2fda9e55&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fda9e55",
  null
  
)

export default component.exports